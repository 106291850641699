import { useEffect, useState } from 'react';
import logo from './ctx_logo.png';
import './App.css';
import { version } from './version.js';

const gatewayUrl = 'https://97tmrs1f1h.execute-api.ap-south-1.amazonaws.com/prod';
// Dev 'https://97tmrs1f1h.execute-api.ap-south-1.amazonaws.com/prod';
// Stage 'https://491m4z3kqb.execute-api.us-east-2.amazonaws.com/prod';
const idValidation = '[a-zA-Z0-9]{6}-[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}';
const deviceValidation = '[a-zA-Z0-9]{2}-[a-zA-Z0-9]{6}';
const zipValidation = '[0-9]{5}';

const noneFound = 'No Adherence Reports Found';
const reportsFound = 'Adherence Report Found';
const notOnboarded = 'Participant has not onboarded';
const noFileSelected = 'No File Selected';
const invalidInput = 'The information you entered does not match our records, please check your entry and try again';
const error301 = 'Cannot generate adherence report (Error Code: 301), please check your entry and try again. If the error persists, please contact Cognito Therapeutics';
const error202 = 'Cannot generate adherence report (Error Code: 202), please check your entry and try again. If the error persists, please contact Cognito Therapeutics';
const error203 = 'Error polling for Report (Error Code: 203), please "Check for Reports" after a minute to locate your Report';
const error103 = 'Cannot generate adherence report (Error Code: 103), please check your entry and try again. If the error persists, please contact Cognito Therapeutics';

function App() {
  const [subjectId, setSubjectId] = useState('');
  const [deviceId, setDeviceId] = useState('');
  const [zip, setZip] = useState('');
  const [uploadFiles, setUploadFiles] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadComplete, setUploadComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [searching, setSearching] = useState(false);
  const [waitingMessage, setWaitingMessage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [uploadable, setUploadable] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);

  useEffect(() => {
    const goodSub = subjectId.match(idValidation);
    const goodDevice = deviceId.match(deviceValidation);
    const goodZip = zip.match(zipValidation);

    if (uploadFiles && (goodSub !== null) && (goodDevice !== null) && (goodZip !== null)) {
      setUploadable(true);
    }
    else {
      setUploadable(false);
    }
  }, [uploadable, deviceId, subjectId, zip, uploadFiles])

  async function s3ListButton() {
    clearMessages();
    await s3List();
  }

  async function s3List(fromTime = 0) {
    setFileList([]);
    setSearching(true);
    let fileListResponse;
    try {
      let getUrl = `${gatewayUrl}/logUploader?device_id=${deviceId}&subject_id=${subjectId}&zip=${zip}&list=true`;
      if (fromTime) getUrl += `&fromTime=${fromTime}`;
      const getResponse = await fetch(getUrl);
      if(getResponse.status === 200) {
        fileListResponse = await getResponse.json();
      }
    }
    catch (e) {
      setTimeout(s3List, 10000);
      return;
    }

    if (fileListResponse && fileListResponse.list) {
      if (fileListResponse.list.length === 0) {
        setSearching(false);
        setWaitingMessage(noneFound);
      }
      else {
        setSearching(false);
        setWaitingMessage(reportsFound);
      }
      const listItems = fileListResponse.list.map((item) => <li key={item.name}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a><br /></li>)
      setFileList(listItems);
    } else {
      setTimeout(s3List, 5000);
    }
    return;
  }

  async function s3Check(fromTime) {
    let fileCheck;
    try {
      let getUrl = `${gatewayUrl}/logUploader?device_id=${deviceId}&subject_id=${subjectId}&list=false`;
      if (fromTime) getUrl += `&fromTime=${fromTime}`;
      const getResponse = await fetch(getUrl);
      if(getResponse.status === 200) {
        fileCheck = await getResponse.json();
        if (fileCheck.message && fileCheck.message.match(/FAILURE/)) {
          setUploadComplete(false);
          setProcessing(false);
          setWaitingMessage('');
          setErrorMessage(error301);
        }
        else if (fileCheck.message && (fileCheck.message.match(/SUCCESS/))) {
          setUploadComplete(true);
          setProcessing(false);
          setWaitingMessage('');
          s3List(fromTime)
        }
        else {
          setWaitingMessage(fileCheck.message);
          setTimeout(() => s3Check(fromTime), 5000);
        }
      }
    }
    catch(e) {
      console.error('Check Failed: ', e);
      setErrorMessage(error203);
      setUploadComplete(false);
      setProcessing(false);
      setWaitingMessage('');
    }
  }

  function clear() {
    clearMessages();
    setSubjectId('');
    setDeviceId('');
    setZip('');
    setUploadFiles(null);
    setFileList([]);
    let archive = document.getElementsByName('archive');
    archive[0].value = '';
  }

  function clearMessages() {
    setProcessing(false);
    setSearching(false);
    setUploadComplete(false);
    setErrorMessage('');
    setWaitingMessage('');
    setFileList([]);
  }

  function uploadHandler() {
    clearMessages();
    const time = Date.now();
    uploadFile(time);
  }

  async function uploadFile(fromTime = 0, acceptNoOnboard = false) {
    if (!uploadFiles) {
      setErrorMessage(noFileSelected);
      return;
    }
    try {
      setProcessing(true);
      const presignUrl = `${gatewayUrl}/logUploader?acceptNoOnboard=${acceptNoOnboard}`;

      // 1. Get upload URL
      let resp = await fetch(presignUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subject_id: subjectId,
          device_id: deviceId,
          zip: zip,
        })
      });
      const presign = await resp.json();

      if (resp.status === 400) {
        if (presign.message === 'Subject has not onboarded') {
          setProcessing(false);
          setDialogVisible(true);
        }
        else {
          setErrorMessage(invalidInput);
          setProcessing(false);
        }
        return;
      }
      if (!presign.url) {
        setErrorMessage(error202);
        setProcessing(false);
        return;
      }
      // 2. Upload at URL
      const { url } = presign;
      const populatedUrl = `${url}`

      await fetch(populatedUrl, {
        method: 'PUT',
        body: uploadFiles[0],
      });

      setUploadComplete(true);
      setWaitingMessage("... Polling ...");
      setTimeout(() => s3Check(fromTime), 5000);
    } catch (e) {
      setErrorMessage(error103);
      setProcessing(false);
      console.error(e);
    }
    setProcessing(false);
  }

  function noOnboardCancel() {
    clearMessages();
    setDialogVisible(false);
    setErrorMessage(notOnboarded);
  }

  function noOnboardContinue() {
    clearMessages();
    setDialogVisible(false);
    const time = Date.now();
    uploadFile(time, true);
  }

  return (
    <div className="App">
        <img src={logo} alt='Cognito Theraputics Logo'></img><br/>
        <h1 className='title'>Adherence Tool</h1>
        <div className='adhereForm'>
          <div className='adhereInput'>
            <label>Participant ID *</label><br/>
            <input name="subject_id" type="text" value={subjectId}
            onChange={(e) => {
              setSubjectId(e.target.value.toUpperCase())
            }}
            pattern={idValidation}></input>
            <br/>
            <span className='hint'>format: Study-Site ID-Participant ID, ie. CA0011-100-000</span>
            <br/>
            <label>Device ID *</label><br/>
            <input name="device_id" type="text" value={deviceId}
            onChange={(e) => setDeviceId(e.target.value.toUpperCase())}
            pattern={deviceValidation}></input>
            <br/>
            <span className='hint'>format: Device Serial Number, ie. C5-000000</span>
            <br/>
            <label>Site Zip Code *</label><br/>
            <input name="site_zip" type="text" value={zip}
            onChange={(e) => setZip(e.target.value)}
            pattern={zipValidation}></input>
            <br/>
            <label>Choose Device File to Upload</label><br/>
            <input name="archive" type="file" onChange={(e) => setUploadFiles(e.target.files)}></input>
            <br/>
          </div>
          <button className='primary button' disabled={processing || !uploadable} onClick={uploadHandler}>Upload</button>
          <br/>
          <button className='secondary button' disabled={processing || !subjectId || !deviceId || !zip} onClick={s3ListButton}>Check for Reports</button>
          <button className='secondary button' disabled={processing} onClick={clear}>Clear</button>
          <br/>
          {processing && 
            <>
              <span>Upload Underway, please don't refresh the page</span>
              <br/>
              <span className="loader-green"></span>
              <br/>
              <span>Uploading</span>
            </>
          }
          {searching && 
            <>
              <span>Checking for Reports</span>
              <br/>
              <span className="loader-green"></span>
              <br/>
            </>
          }

          <div className='error'>
            {errorMessage && <span>{errorMessage}</span> } <br/>
          </div>
          {uploadComplete && !errorMessage && fileList && (fileList.length === 0) &&
            <>
              <span>After a few minutes you should be able to access your report:</span> <br/>
              <span className="loader-blue"></span>
              <br/>
              <span>Parsing Uploaded Data</span>
              <br/>
            </>
          }
          { waitingMessage && <span>{waitingMessage}</span> }
          <ul>{ fileList }</ul>
        </div>
        <div className='version'>
          <span>v{version}</span>
        </div>
        <br/>
        <div className='dialog' hidden={!dialogVisible}>
          <div className='dialog-content'>
            <span>Participant has not been onboarded</span>
            <br/>
            <button className='secondary button autofocus' disabled={processing} onClick={noOnboardCancel}>Cancel</button>
            <button className='secondary button' disabled={processing} onClick={noOnboardContinue}>Proceed</button>
          </div>
        </div>
    </div>
  );
}

export default App;
